<template>
  <router-view>
  </router-view>
</template>
<script>
// import minxin from "@/mixins/index.js"; // 引入minixn
export default {
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
   
  },
  methods: {
    
  },
};
</script>
<style>
body,
html {
  /* height: 100%; */
  overflow-x: hidden;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.qrcode {
  text-align: center !important;
}
.qrcode img {
  width: 150px;
  margin: 0 auto;
}
.content2 p {
  margin: 0;
  padding: 0;
  color: white !important;
}
body {
  /* background: #f2f2f2; */
  font-size: 14px !important;
  overflow-x: hidden;
}
.font {
  font-family: YouSheBiaoTiHei;
}
.absolute {
  position: absolute;
}
/* 背景 */
.bgColor {
  width: 100%;
  height: 100%;
  background: #f3f3f3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.flex {
  display: flex;
  align-items: center;
}
.index .van-popup,
.info .van-popup {
  background: none !important;
}
/* 导航栏 */
.van-nav-bar {
  background: none !important;
}
.van-nav-bar .van-nav-bar__left .van-icon {
  color: white !important;
  font-size: 18px !important;
}
.van-nav-bar__title,
.van-nav-bar__text {
  color: white !important;
}
.van-hairline--bottom::after {
  border: 0 !important;
}

/* 日历样式 */
.el-date-picker-calendar__header {
  margin: 0 !important;
}
.el-picker-panel-calendar__icon-btn,
.el-date-picker-calendar__header-label,
.el-date-table-calendar th {
  color: white !important;
}
/* .van-search{
  background: none !important;
} */
/* 搜索框 */
.van-search__content {
  background-color: #eff4fb !important;
  border-radius: 10px !important;
}
.van-field__left-icon .van-icon {
  font-size: 22px !important;
  color: #2a4c89 !important;
}
/* 底部导航 */
.nav-item {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  font-size: 12px;
  padding: 8px 0;
  background: #e76e17;
  box-shadow: 1px 1px 4px 1px #f5a165;
}
.navs {
  width: 43%;
  text-align: center;
  line-height: 1.5;
  color: #dedede;
}
.navs-a {
  color: #ffb443;
}
.nav-item img {
  width: 21px;
  height: 21px;
}
#formInput .van-checkbox__label {
  color: #aaaaaa !important ;
}
#formInput .van-cell {
  background: #eff7fe !important;
  margin-bottom: 10px !important;
}
#manage-login .van-cell {
  background: rgb(239 247 254 / 13%) !important;
}
#manage-login .van-field__label {
  color: white !important;
}
#manage-login .van-field__control {
  color: white !important;
}
#info .van-popup {
  background: none !important;
}
.ad {
  width: 98%;
  margin: 0 auto;
  margin-top: 15px;
}
.ad div {
  flex: 1;
  padding: 5px;
}
.ad img {
  width: 100%;
  border-radius: 5px;
}
/* 进度条颜色 */
.vm-progress.is-info .vm-progress-bar__inner {
  background: linear-gradient(to right, #febfd0, #0ed2aa) !important;
}
.vm-progress-bar__innerText {
  color: #ce4031 !important;
  position: relative;
  top: 20px;
  left: 20px;
}
.vm-progress-bar__innerText::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  bottom: 21px;
  right: 15px;
}
.vm-progress-bar__outer {
  overflow: inherit !important;
}
.address .van-cell {
  font-weight: bold !important;
  font-size: 15px !important;
  color: black !important;
}
.content p {
  padding: 0;
  margin: 5px;
}
.card1-tit3 p {
  margin: 0 !important;
  padding: 0 !important;
}
.animate__delay-0-5s {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.delay-0-5s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.delay-1-5s {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.between {
  justify-content: space-between;
}
.align {
  align-items: center;
}
.index .qrcode {
  background: white;
  width: 212px;
  height: 212px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.index .qrcode img {
  width: 190px;
  height: 190px;
}
.newClass .van-dialog__header {
  font-size: 40px;
  line-height: 60px;
}
.newClass .van-dialog__message--has-title {
  font-size: 30px;
  line-height: 80px;
}
.newClass .van-dialog__confirm {
  font-size: 40px;
  line-height: 80px;
  height: 100px;
}
</style>
